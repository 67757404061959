/**
 * @flow
 */
import React from 'react';
import {useLabels, useLang} from '../../services/langReducer';

const WhyCoship = () => {
  const lang = useLang();
  const langName = lang === 'ko' ? 'korean' : 'english';
  const labels = useLabels();
  return (
    <section id="about4" className="about about-2 about-4 pb-40">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-7">
            <div className="row heading heading-2">
              <div className="col-sm-12 col-md-12 col-sm-12">
                <h2 className="heading__title">{labels.whyCoshipLabel}</h2>
              </div>{/* /.col-lg-12 */}
              <div className="col-sm-12 col-md-5 col-lg-5">
                <div className="carousel owl-carousel carousel-dots" data-slide={1} data-slide-md={1} data-slide-sm={1} data-autoplay="true" data-nav="false" data-dots="true" data-space={0} data-loop="true" data-speed={700}>
                  <div className="fancybox-item">
                    <div className="fancybox__icon">
                      <i className="icon-worldwide" />
                    </div>{/* /.fancybox-icon */}
                    <div className="fancybox__content">
                      <h4 className="fancybox__title">{labels.canadaWestLabel}</h4>
                      <p className="fancybox__desc">{labels.canadaWestAddr}</p>
                    </div>{/* /.fancybox-content */}
                  </div>{/* /.fancybox-item */}
                  <div className="fancybox-item">
                    <div className="fancybox__icon">
                      <i className="icon-worldwide" />
                    </div>{/* /.fancybox-icon */}
                    <div className="fancybox__content">
                      <h4 className="fancybox__title">{labels.canadaCentralLabel}</h4>
                      <p className="fancybox__desc">{labels.canadaCentralAddr}</p>
                    </div>{/* /.fancybox-content */}
                  </div>{/* /.fancybox-item */}
                  <div className="fancybox-item">
                    <div className="fancybox__icon">
                      <i className="icon-worldwide" />
                    </div>{/* /.fancybox-icon */}
                    <div className="fancybox__content">
                      <h4 className="fancybox__title">{labels.canadaEastLabel}</h4>
                      <p className="fancybox__desc">{labels.canadaEastAddr}</p>
                    </div>{/* /.fancybox-content */}
                  </div>{/* /.fancybox-item */}
                  <div className="fancybox-item">
                    <div className="fancybox__icon">
                      <i className="icon-worldwide" />
                    </div>{/* /.fancybox-icon */}
                    <div className="fancybox__content">
                      <h4 className="fancybox__title">{labels.koreaLabel}</h4>
                      <p className="fancybox__desc">{labels.koreaAddr}</p>
                    </div>{/* /.fancybox-content */}
                  </div>{/* /.fancybox-item */}
                </div>{/* /.carousel */}
              </div>{/* /.col-lg-5 */}
              <div className="col-sm-12 col-md-7 col-lg-7">
                <p className="heading__desc mb-30">{labels.whyCoshipLine_1}</p>
                <p>{labels.whyCoshipLine_2}</p>
                <p className="mb-30">{labels.whyCoshipLine_3}</p>
                <a className='btn btn__primary' href={`https://g.page/r/CcB32nGNlwyREBM/review`} target={'_blank'}>{labels.viewGoogleReview}</a>
              </div>{/* /.col-lg-7 */}
            </div>{/* /.row */}
          </div>{/* /.col-lg-7 */}
          <div className="col-sm-12 col-md-9 col-lg-5">
            <div className=""> {/*about__img*/}
              <img src={`assets/public/images/home/whycoship.${langName}.jpg`} alt="about img" className="img-fluid" />
              {/*<span>More About Us!</span>*/}
            </div>{/* /.about-img */}
          </div>{/* /.col-lg-5 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

export default WhyCoship;
