/**
 * @flow
 */
import React from 'react';
import {useLabels} from '../../services/langReducer';
import {globalSetPosition} from './GoogleMap';

export const locationCoords = [
  //{lat: 49.1888299, lng: -122.6650632},
  //{lat: 49.188615, lng: -122.661494},
  //{lat: 49.188671, lng: -122.662727},
  // {lat: 49.189225, lng: -122.663221, addr: `1-20120 102B AVE, LANGLEY, BC, CANADA V1M 3G9`},
  {lat: 49.2273048, lng: -122.8467238, addr: `1501 HARTLEY AVE, BC, CANADA V3K 6Z7`},
  {lat: 49.2439232, lng: -122.893961, addr: `#219-4501 North Rd Room 205, Burnaby, BC V3N 4J5`},
  {lat: 50.9924359, lng: -114.061003, addr: `110 Forge Rd SE, Calgary, Alberta Canada T2H 0S8`},
  {lat: 43.6785125, lng: -79.5907779, addr: `129 Carlingview Drive, Etobicoke, Ontario, Canada M9W 5E7`},
  {lat: 37.7144634, lng: 126.7939135, addr: `South Korea, Gyeonggi-do, Goyang-si, Ilsandong-gu, Gobong-ro 678beon-gil`},
];

const GlobalLocations = () => {
  const labels = useLabels();
  const onLocationChange = (index: number) => {
    // console.log('hello index', index);
    globalSetPosition(locationCoords[index]);
  };
  React.useEffect(() => {
    setTimeout(() => {
      globalSetPosition(locationCoords[0]);
    }, 1000);
  }, []);
  return (
    <section id="cta1" className="cta cta-1 border-top pt-40 pb-10">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="contact-panel contact-panel-2">
              <div className="contact__panel-header d-flex align-items-center">
                <i className="icon-placeholder" />
                <h4 className="contact__panel-title">Global Locations</h4>
              </div>
              <div id="accordion">
                {labels.locations.map(({label, lines}, index) => {
                  return (
                    <div className="accordion-item" key={index}>
                      <div className={`accordion__item-header ${index === 0 ? 'opened' : ''}`} data-toggle="collapse" data-target={`#collapse${index + 1}`}>
                        <a className="accordion__item-title" href="#" onClick={() => onLocationChange(index)}>{label}</a>
                      </div>{/* /.accordion-item-header */}
                      <div id={`collapse${index + 1}`} className={`collapse ${index === 0 ? 'show' : ''}`} data-parent="#accordion">
                        <div className="accordion__item-body">
                          <ul className="contact__list list-unstyled">
                            {lines.map((line, index) => <li key={index}>{line}</li>)}
                          </ul>
                        </div>{/* /.accordion-item-body */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>{/* /.contact-panel */}
          </div>{/* /.col-lg-4 */}
          <div className="col-sm-12 col-md-12 col-lg-3 text-right invisible">
            <h2 className="cta__title">{'COSHIP'}</h2>
          </div>{/* /.col-lg-3 */}
          <div className="col-sm-12 col-md-12 col-lg-5 invisible">
            {/*<form>*/}
            {/*  <div className="form-group d-flex">*/}
            {/*    <input type="text" className="form-control mr-30" placeholder="Your Email Address" />*/}
            {/*    <button className="btn btn__primary btn__hover3">Sign Up!</button>*/}
            {/*  </div>*/}
            {/*</form>*/}
          </div>{/* /.col-lg-5 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

export default GlobalLocations;
